import React, { Fragment } from "react";
import { graphql } from "gatsby";
import ParallaxHeader from "../components/ParallaxHeader";
import { motion } from "framer-motion";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import { Tab } from "@headlessui/react";
import Markdown from "markdown-to-jsx";
import { Helmet } from "react-helmet";

export const CapabilitiesPageTemplate = ({ title, image, tabs }) => {
  const headerImage = getImage(image) || image;
  return (
    <div className="flex flex-col items-center justify-center w-full">
      <ParallaxHeader className="h-60" image={headerImage} factor={0.7}>
        <motion.h1
          initial={{ x: -10, opacity: 0 }}
          animate={{
            x: 0,
            opacity: 1,
            transition: {
              duration: 0.5,
              type: "ease",
            },
          }}
          className="z-10 text-white text-5xl "
        >
          {title}
        </motion.h1>
      </ParallaxHeader>
      <section className="w-full flex flex-col items-center bg-beige-300/[0.3] justify-center">
        <div className="container  flex flex-col items-center justify-center pt-5">
          <Tab.Group>
            <Tab.List>
              {tabs.map((tab, idx) => (
                <StyledTab key={idx}>{tab.title}</StyledTab>
              ))}
            </Tab.List>
            <Tab.Panels className="w-full">
              {tabs.map((tab, idx) => (
                <TabContent key={idx} tab={tab}></TabContent>
              ))}
            </Tab.Panels>
          </Tab.Group>
        </div>
      </section>
    </div>
  );
};
export default function CapabilitiesPage({ data }) {
  const { frontmatter } = data.markdownRemark;

  return (
    <>
      <Helmet>
        <title>Our Capabilities - Brown Construction Services</title>
      </Helmet>
      <CapabilitiesPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        tabs={frontmatter.tabs}
      >
        Capabilities
      </CapabilitiesPageTemplate>
    </>
  );
}

function StyledTab({ children }) {
  return (
    <Tab as={Fragment}>
      {({ selected }) => (
        <button
          className={` py-4 w-fit px-2 sm:w-[200px] text-center
                      ${
                        selected
                          ? "text-navy border-b-4 border-navy"
                          : "text-black/[0.4]  border-b-4 border-black/[0.4] hover:text-navy/[0.8] hover:border-navy/[0.8]"
                      }
                    `}
        >
          {children}
        </button>
      )}
    </Tab>
  );
}

function TabContent({ tab }) {
  return (
    <Tab.Panel>
      <div className="w-full p-5 sm:p-10 ">
        <div className="overflow-auto">
          <h1 className="text-brown text-3xl mb-5">{tab.header}</h1>
          {tab.image && (
            <img
              className="sm:mx-5 mb-4 shadow  sm:float-right"
              src="/img/mainpitch.jpg"
              style={{ width: "400px", height: "auto" }}
            />
          )}
          <Markdown className="md-content md-theme-color">
            {tab.content}
          </Markdown>
        </div>
        {tab.projects && tab.projects.length && (
          <>
            <hr className="w-full border-b-2 border-navy my-8 text-center "></hr>
            <h1 className="text-navy text-2xl">Project Examples</h1>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mt-6">
              {tab.projects.map((project, idx) => (
                <ProjectCard key={idx} project={project} />
              ))}
            </div>
          </>
        )}
      </div>
    </Tab.Panel>
  );
}

function ProjectCard({ project }) {
  const logo = getImage(project.logo);
  return (
    <div className="shadow-lg bg-white rounded-sm p-8">
      <div className="flex flex-row items-start pb-5 w-full">
        {project.logo && (
          <div className="">
            <GatsbyImage image={logo} />
          </div>
        )}
        <div className="ml-10 flex-grow flex h-full flex-col justify-between">
          <h1 className="text-navy text-2xl mb-2">{project.title}</h1>
          {project.subtitle && (
            <h3 className="text-navy">{project.subtitle}</h3>
          )}
          <hr className="w-[90%] border-b-4 mt-5 border-navy pr-8"></hr>
        </div>
      </div>
      <div className="text-darkbrown/[0.8] ">
        <Markdown>{project.details}</Markdown>
      </div>
    </div>
  );
}

export const capabilitiesPageQuery = graphql`
  query CapabilitiesPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        tabs {
          title
          header
          content
          image {
            childImageSharp {
              gatsbyImageData(quality: 100, width: 400)
            }
          }
          projects {
            title
            subtitle
            logo {
              childImageSharp {
                gatsbyImageData(quality: 64, width: 100)
              }
            }
            details
          }
        }
      }
    }
  }
`;
